import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Seo from '../components/Seo'
import { useAppContext } from '../components/AppContext'

const DocIndexPage = () => {
  const { language } = useAppContext()

  const indexPageQuery = graphql`
    query indexPage {
      ru: markdownRemark(fields: { slug: { eq: "/docs/ru/" } }) {
        html
        frontmatter {
          title
          description
        }
      }
      en: markdownRemark(fields: { slug: { eq: "/docs/en/" } }) {
        html
        frontmatter {
          title
          description
        }
      }
      pt: markdownRemark(fields: { slug: { eq: "/docs/pt/" } }) {
        html
        frontmatter {
          title
          description
        }
      }
      zh: markdownRemark(fields: { slug: { eq: "/docs/zh/" } }) {
        html
        frontmatter {
          title
          description
        }
      }
    }
  `
  const indexPage = useStaticQuery(indexPageQuery)

  return (
    <>
      <Seo
        title={indexPage[language]?.frontmatter?.title}
        description={
          indexPage[language]?.frontmatter?.description ||
          indexPage[language]?.excerpt
        }
      />
      <div dangerouslySetInnerHTML={{ __html: indexPage[language]?.html }} />
    </>
  )
}

export default DocIndexPage
